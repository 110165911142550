// export const slugs = {
//   "new_book": "/new-book",
//   "books_details": "/admin/books/",
//   "all_books": "/all-books",
//   "db_slug_book": "books",

//   "new_chapter_wise_exam": "/new-chapter",
//   "chapter_details": "/chapters/",
//   "all_chapters": "/all-chapters",
//   "db_slug_chapter_wise_exam": "chapter_wise_exam",

//   "new_daily_exam": "/new-daily-exam",
//   "daily_exam_details": "/daily-exams/",
//   "all_daily_exam": "/all-daily-exam",
//   "db_slug_daily_exam": "daily_exam",

//   "new_weekly_exam": "/new-weekly-exam",
//   "weekly_exam_details": "/weekly-exams/",
//   "all_weekly_exam": "/all-weekly-exam",
//   "db_slug_weekly_exam": "weekly_free_exam",

//   "all_weekly_results": "/all-weekly-results",
//   "db_slug_weekly_results": "weekly_result",

//   "new_buy_exam": "/new-student-exam",
//   "buy_exam_details": "/student-exams/",
//   "all_buy_exam": "/all-student-exam",
//   "db_slug_buy_exam": "buy_exam",
//   "db_slug_buy_exam_result": "buy_results",
//   "db_slug_buy_results":"buy_results",
//   "classroom": "/classroom",
//   "start_class_exam": "/start-class-exam",
  
//   "buy_result_admin_view":"/student-result-admin-view",
//   "all_buy_results": "/all-student-results",
//   "db_slug_buy_results": "buy_results",
//   "new_teacher": "/new-teacher",
//   "teacher_details": "/teachers/",
//   "all_teachers": "/all-teachers",

//   "new_demo_exam": "/new-demo-exam",
//   "demo_exam_details": "/demo-exams/",
//   "all_demo_exam": "/all-demo-exam",
//   "db_slug_demo_exam": "demo_exam",

//   "weekly_exam": "/all-weekly-exams-list",
//   "start_weekly_exam": "/start-weekly-exam",
//   "weekly_exam_result_list": "/my-weekly-exam-results",
//   "weekly_exam_result": "/weekly-exam-result",

//   "new_exam": "/new-exam",
//   "exam_details": "/exams/",
//   "all_exam": "/all-exam",
//   "db_slug_paid_exam": "paid_exam",
//   "result_admin_view":"/result-admin-view",
//   "chapter_result_admin_view":"/chapter-result-admin-view",

//   "result_details": "/results/",
//   "all_results": "/all-results",
//   "all_chapter_results": "/all-chapter-results",
//   "db_slug_paid_results": "paid_results",

//   "daily_result_details": "/daily-results/",
//   "all_daily_results": "/all-daily-result",
//   "db_slug_daily_results": "daily_results",

//   "chapter_wise_result_details": "/chapter-wise-results/",
//   "all_chapter_wise_results": "/all-chapter-wise-result",
//   "db_slug_chapter_wise_results": "chapter_wise_results",

//   "new_student": "/new-student",
//   "student_details": "/students/",
//   "all_students": "/all-students",
//   "db_slug_user_data": "user_data",

//   "new_batch": "/new-batch",
//   "batch_details": "/batches/",
//   "all_batch": "/all-batches",
//   "db_slug_batch": "student_batch",



//   "exam": "/all-exams-list",
//   "demo_exam": "/all-demo-exam-list",
//   "chapter_wise_exam": "/all-chapters-list",
//   "daily_exam": "/all-daily-exam-list",

//   "start_exam": "/start-exam",
//   "start_demo_exam": "/start-demo-exam",
//   "start_chapter_wise_exam": "/start-chapter-exam",
//   "start_daily_exam": "/start-daily-exam",

//   "exam_result_list": "/my-exam-results",
//   "daily_exam_result_list": "/my-daily-results",
//   "chapter_wise_exam_result_list": "/my-chapterwise-results",

//   "exam_result": "/exam-result",
//   "daily_exam_result": "/daily-exam-result",
//   "chapter_exam_result": "/chapter-exam-result",
// }

export const slugs = {

  "classroom": "/classroom",
  "start_class_exam": "/start-class-exam",
  
  "new_book": "/new-book",
  "books_details": "/admin/books/",
  "all_books": "/all-books",
  "db_slug_book": "books",

  "new_demo_exam": "/new-demo-exam",
  "demo_exam_details": "/demo-exams/",
  "all_demo_exam": "/all-demo-exam",
  "db_slug_demo_exam": "demo_exam",

  "new_exam": "/new-exam",
  "exam_details": "/exams/",
  "all_exam": "/all-exam",
  "db_slug_paid_exam": "paid_exam",

  "new_exam_2": "/new-exam-2",
  "exam_2_details": "/exams-2/",
  "all_exam_2": "/all-exam-2",
  "db_slug_exam_2": "weekly_free_exam",
  "db_slug_weekly_exam": "weekly_free_exam",

  "result_admin_view":"/result-admin-view",
  "chapter_result_admin_view":"/chapter-result-admin-view",

  "result_details": "/results/",
  "all_results": "/all-results",
  "all_chapter_results": "/all-chapter-results",
  "db_slug_paid_results": "paid_results",
  "db_slug_buy_results": "paid_results",

  "new_exam_package": "/new-exam-package",
  "exam_packages_details": "/exam-packages/",
  "all_exam_packages": "/all-exam-packages",
  "db_slug_exam_packages": "exam_packages",

  "new_batch_order": "/new-batch-order",
  "batch_order_details": "/batch-order/",
  "all_batch_orders": "/all-batch-order",
  "db_slug_batch_orders": "batch_order",
  "exam_payment": "/exam-payment",

  "new_course_package": "/new-course",
  "course_packages_details": "/course-details/",
  "all_course_packages": "/all-courses",
  "db_slug_chapters": "paid_course",

  "daily_result_details": "/daily-results/",
  "all_daily_results": "/all-daily-result",
  "db_slug_daily_results": "daily_results",

  "chapter_wise_result_details": "/chapter-wise-results/",
  "all_chapter_wise_results": "/all-chapter-wise-result",
  "db_slug_chapter_wise_results": "chapter_wise_results",

  "new_student": "/new-student",
  "student_details": "/students/",
  "all_students": "/all-students",
  "db_slug_user_data": "user_data",

  "new_batch": "/new-batch",
  "batch_details": "/batches/",
  "all_batch": "/all-batches",
  "db_slug_batch": "student_batch",
  
  "new_chapter": "/new-chapter",
  "chapter_details": "/chapters/",
  "all_chapter": "/all-chapters",

  "new_teacher": "/new-teacher",
  "teacher_details": "/teachers/",
  "all_teachers": "/all-teachers",

  "exam": "/all-exams-list",
  "weekly_exam": "/all-weekly-exams-list",
  "question_bank_exam": "/all-question-bank-exams-list",
  "demo_exam": "/all-demo-exam-list",
  "chapter_wise_exam": "/all-chapters-list",
  "course": "/all-courses-list",
  "daily_exam": "/all-daily-exam-list",

  "start_course": "/start-course",
  "start_exam": "/start-exam",
  "start_demo_exam": "/start-demo-exam",
  "start_chapter_wise_exam": "/start-chapter-exam",
  "start_daily_exam": "/start-daily-exam",
  "start_weekly_exam": "/start-weekly-exam",

  "batch_payment": "/batch-payment",
  "batch_exam": "/batch-exam",

  "exam_result_list": "/my-exam-results",
  "daily_exam_result_list": "/my-daily-results",
  "chapter_wise_exam_result_list": "/my-chapterwise-results",

  "exam_result": "/exam-result",
  "daily_exam_result": "/daily-exam-result",
  "chapter_exam_result": "/chapter-exam-result",
}