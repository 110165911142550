import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ExamBlockPlaceHolder } from '../../Constant/api';
export const ExamBlock = ({ item, examSlug, verified }) => {
  const location = useLocation();
  return (
    <div style={{
      padding: "5px", margin: "5px 0px", border: "1.5px solid #bbb",
      borderRadius: "5px",
    }}>
      {/* {item.meta.featured_image !== "" ?
        <img className="col-12" src={item.meta.featured_image}
          style={{ objectFit: 'contain', height: "100px", borderRadius: "3px" }} />
        :
        <img className="col-12"
          src={ExamBlockPlaceHolder}
          style={{ objectFit: 'contain', height: "100px", borderRadius: "3px" }} />
      } */}
      <br />
      <div>
        {item.title.rendered}&nbsp;
      </div>
      {(!JSON.stringify(location).includes("all-demo-exam-list") && item.meta.exam_question_block_student == undefined) ?
        <div style={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
          <div className="sign-in-button-1 col-8 Link" style={{ color: "#fff", opacity: 0.5 }}
          >
            Verify to Start
          </div>
        </div>
        :
        <div style={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
          {verified == 1 ?
            <Link className="sign-in-button-1 col-8 Link"
              to={`${examSlug}/${item.slug}`}
            >
              <div style={{ color: "#fff" }}>
                Start Exam
              </div>
            </Link>
            :
            <a className='Link'
              href=' https://wa.me/9779808699352?span=
नमस्ते Gorkhey Korean 🙏
म Gorkhey Koreanको प्रयोगकर्ता हुँ।
               '
            >
              <div className="sign-in-button-1 col-8 Link" style={{ color: "#000", backgroundColor: "#fff" }}
                onClick={() => {

                }}
              >
                Buy Exam
                {item.meta.exam_price !== "" &&
                  <>
                    &nbsp;at Rs. {item.meta.exam_price}
                  </>
                }
              </div>
            </a>
          }
        </div>
      }
    </div >
  )

}